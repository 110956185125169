import { createLoot } from "../store/helperFuncs";
import BaseActivity from "./BaseActivity";
import { Items } from "./Items";
import Skills from "./Skills";
import Upgrades, { UpgradeTypes } from "./Upgrades";

class MiningActivity extends BaseActivity {
    getProgressNeeded: Function = (activity, playerUpgrades) => {
        let progressNeeded = activity.baseProgressNeeded
        let multiplier = 0
        for (let upgradeId in playerUpgrades) {
            let upgrade = Upgrades[upgradeId].levels[playerUpgrades[upgradeId] - 1]
            if (upgrade.effects) {
               for (let effectKey in upgrade.effects) {
                let effect = upgrade.effects[effectKey]
                if (effect.upgradeType === UpgradeTypes.MINING_SPEED) {
                    multiplier += effect.multiplier
                }
               }
            }
        }
        return progressNeeded * ((100 - multiplier) / 100)
    }
}

const MiningActivities = {
    MINE_RUNE_ESSENCE: {
        ...(new MiningActivity()),
        id: "MINE_RUNE_ESSENCE",
        name: "Mine Rune Essence",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.RUNE_ESSENCE.id)
        ],
        baseProgressNeeded: 2000,
        expGranted: 5,
    } as MiningActivity,

    MINE_COPPER_ORE: {
        ...(new MiningActivity()),
        id: "MINE_COPPER_ORE",
        name: "Mine Copper Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.COPPER_ORE.id)
        ],
        baseProgressNeeded: 4000,
        expGranted: 10,
    } as MiningActivity,

    MINE_TIN_ORE: {
        ...(new MiningActivity()),
        id: "MINE_TIN_ORE",
        name: "Mine Tin Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.TIN_ORE.id)
        ],
        baseProgressNeeded: 4000,
        expGranted: 10,
    } as MiningActivity,

    MINE_IRON_ORE: {
        ...(new MiningActivity()),
        id: "MINE_IRON_ORE",
        name: "Mine Iron Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.IRON_ORE.id)
        ],
        baseProgressNeeded: 6000,
        expGranted: 20,
        levelRequired: 15,
    } as MiningActivity,

    MINE_SILVER_ORE: {
        ...(new MiningActivity()),
        id: "MINE_SILVER_ORE",
        name: "Mine Silver Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.SILVER_ORE.id)
        ],
        baseProgressNeeded: 4000,
        expGranted: 18,
        levelRequired: 30,
    } as MiningActivity,

    MINE_COAL_ORE: {
        ...(new MiningActivity()),
        id: "MINE_COAL_ORE",
        name: "Mine Coal Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.COAL_ORE.id)
        ],
        baseProgressNeeded: 6000,
        expGranted: 25,
        levelRequired: 30,
    } as MiningActivity,

    MINE_GOLD_ORE: {
        ...(new MiningActivity()),
        id: "MINE_GOLD_ORE",
        name: "Mine Gold Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.GOLD_ORE.id)
        ],
        baseProgressNeeded: 4000,
        expGranted: 25,
        levelRequired: 40,
    } as MiningActivity,

    MINE_MITHRIL_ORE: {
        ...(new MiningActivity()),
        id: "MINE_MITHRIL_ORE",
        name: "Mine Mithril Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.MITHRIL_ORE.id)
        ],
        baseProgressNeeded: 6000,
        expGranted: 40,
        levelRequired: 50,
    } as MiningActivity,

    MINE_ADAMANTITE_ORE: {
        ...(new MiningActivity()),
        id: "MINE_ADAMANTITE_ORE",
        name: "Mine Adamantite Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.ADAMANTITE_ORE.id)
        ],
        baseProgressNeeded: 7500,
        expGranted: 55,
        levelRequired: 70,
    } as MiningActivity,

    MINE_RUNITE_ORE: {
        ...(new MiningActivity()),
        id: "MINE_RUNITE_ORE",
        name: "Mine Runite Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.RUNITE_ORE.id)
        ],
        baseProgressNeeded: 8000,
        expGranted: 80,
        levelRequired: 80,
    } as MiningActivity,
    
    MINE_DRAGONITE_ORE: {
        ...(new MiningActivity()),
        id: "MINE_DRAGONITE_ORE",
        name: "Mine Dragonite Ore",
        skill: Skills.MINING.id,
        itemsGiven: [
            createLoot(Items.DRAGONITE_ORE.id)
        ],
        baseProgressNeeded: 10000,
        expGranted: 120,
        levelRequired: 90,
    } as MiningActivity,

}

export default MiningActivities