import { useTheme } from '@emotion/react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Tooltip } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase'
import Card from '@mui/material/Card'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ActivityProgressBar from '../components/ActivityProgressBar';
import CustomSvgIcon from '../components/CustomSvgIcon';
import ScreenGridContainer from '../components/ScreenGridContainer';
import SkillInfoBar from '../components/SkillInfoBar';
import { StyledTooltip } from '../components/StyledTooltip';
import { GameFunctions } from '../game/GameFunctionsContext';
import { Items } from '../game/Items'
import Skills from '../game/Skills';
import ThievingActivities from '../game/Thieving';
import { setCurrentActivity } from '../store/currentActivity'
import { toDecimalPlace } from '../store/helperFuncs';
import { getSkillLevel } from '../store/playerStats';

const ThievingChanceTextMemo = ({activityId}) => {

    const GameFuncs = React.useContext(GameFunctions)

    return (
        <>
            {toDecimalPlace(GameFuncs.getThievingSuccessChance(activityId) * 100, 1)}
        </>
    )
}

const ThievingActivityCard = (props) => {



    const { enqueueSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const activity = props.activity
    const theme = useTheme()

    const skillLevel = useSelector((state) => getSkillLevel(state, activity.skill))

    const hasLevel = (skillLevel >= activity.levelRequired)

    const handleClick = (activity) => {
        if (hasLevel) {
            dispatch(setCurrentActivity(activity.id))
        } else {
            enqueueSnackbar(<Typography>Requires level {activity.levelRequired} {Skills[activity.skill].name}</Typography>, 2000)
        }
    }

    //console.log(activity)

    return (

        <ButtonBase sx={{ justifyItems: "center", alignItems: "center", width: 1, borderRadius: "12px", maxHeight: "100vh" }} >
            <Card raised={true} sx={{ padding: "8px", textAlign: "center", justifyItems: "center", width: 1, borderRadius: "12px", maxHeight: "100vh" }} onClick={() => { handleClick(activity) }}>
                {/* Show activity name in red if the required level is not met */}
                {
                    (hasLevel) ?
                        <>
                            <Box sx={{ width: 1, height: 1, padding: "0px", margin: "4px auto", textAlign: "center" }}>
                                <Grid container columns={60}>
                                    <Grid xs={60}>
                                        <Typography variant="h6" fontWeight={""} color={(hasLevel) ? theme.palette.text.primary : theme.palette.error.main} sx={{ marginBottom: "12px" }}>{(hasLevel) ? <></> : <><SvgIcon component={Skills[activity.skill].Icon} sx={{ width: 20, height: 20, alignSelf: "center", margin: "0" }} /> Lv.{activity.levelRequired}</>} {activity.name}</Typography>
                                        {/*<CustomSvgIcon itemId={Items[activity.itemsGiven[0].item].id} size={80} />*/}

                                    </Grid>

                                    <Grid xs={60}>
                                        <SvgIcon component={activity.Icon} sx={{ width: .7, height: .7 }} />
                                    </Grid>

                                    <Grid xs={60}>
                                        <div >
                                            <Typography color={theme.palette.text.primary} alignItems={"center"} mb={1} mt={"-32px"}><AccessTimeIcon sx={{ paddingTop: "4px", margin: "0px -2px -4px 0px", scale: "0.9" }} /><span>{activity.baseProgressNeeded / 1000}s | {activity.expGranted} EXP</span></Typography>
                                            <Typography color={theme.palette.text.primary} alignItems={"center"} mb={1} mt={"-4px"}>Success: <ThievingChanceTextMemo activityId={props.activity.id}/>%</Typography>
                                        </div>
                                    </Grid>

                                    <Grid xs={60}>
                                        <ActivityProgressBar activity={activity} />
                                    </Grid>

                                    <Grid xs={60}>
                                        <Typography color={theme.palette.text.primary} mt={1}>Possible Loot: </Typography>
                                    </Grid>


                                    <Grid xs={60} container columns={60}>
                                        {activity.rollableLoot.map((item) => {
                                            {/*<span key={item}>{Items[item.item].name} x{(item.minAmount === item.maxAmount) ? item.minAmount : `${item.minAmount}-${item.maxAmount}`}</span>*/ }
                                            let popperText = (item.minAmount === item.maxAmount) ? "" + item.minAmount : "" + item.minAmount + "-" + item.maxAmount
                                            //console.log(item)
                                            return <Grid key={item.item} xs={12} sx={{ margin: "2px auto" }}>
                                                <StyledTooltip title={<Typography>{Items[item.item].name}</Typography>} placement='top' arrow>
                                                    <SvgIcon component={Items[item.item].Icon} sx={{ margin: "0px 2px 4px 2px", padding: "4px", width: 48, height: 48, bgcolor: theme.palette.primary.light, borderRadius: "6px" }} />
                                                </StyledTooltip>
                                                <Typography fontSize={12} sx={{ margin: "-8px 0px -4px 0px" }}>{popperText}</Typography>
                                            </Grid>
                                        })}
                                    </Grid>


                                </Grid>
                            </Box>

                        </>
                        :
                        <>
                            <Typography variant="h6" color={theme.palette.error.light}>Requires:<br /><SvgIcon component={Skills[activity.skill].Icon} sx={{ width: 36, height: 36, alignSelf: "center", margin: "8px 0px -10px 0px", bgcolor: theme.palette.primary.light, padding: "4px", borderRadius: "4px" }} /> Lv. {activity.levelRequired} {Skills[activity.skill].name}</Typography>
                        </>
                }

            </Card>
        </ButtonBase>

    )
}

const ActivityCardGrid = ({ skillId }) => {

    const playerLevel = useSelector((state) => getSkillLevel(state, skillId))


    const activities = []
    let shouldBreak = false
    for (let x in ThievingActivities) {
        if (shouldBreak) break
        activities.push(ThievingActivities[x])
        if (ThievingActivities[x].levelRequired > playerLevel) shouldBreak = true
    }



    return (
        <>
            {activities.map((activity) => (

                <Grid xs={12} sm={6} lg={4} xl={3} key={activity.id}>
                    <ThievingActivityCard activity={activity} key={activity.id} />
                </Grid>

            ))}
        </>
    )
}

function ThievingScreen() {

    return (
        <>
            <ScreenGridContainer>
                <Grid container spacing={2} columns={12} margin={0} direction="column"
                    alignItems="center"
                    justifyContent="center" width={1}>
                    <Grid xs={12} padding={"12px 0px"} mb={2}>
                        <SkillInfoBar skillId={Skills.THIEVING.id} />
                    </Grid>
                    <Grid container xs={12} spacing={4}>
                        <ActivityCardGrid skillId={Skills.THIEVING.id} />
                    </Grid>
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default ThievingScreen