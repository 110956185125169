import { baseFarmPatches, FarmingRecipes, FarmPatchTypes } from "../game/Farming"
import { Items } from "../game/Items"
import { baseObjFarming } from "./baseObjects"
import { loadPlayerData } from "./helperFuncs"

const initState = () => {

    /*let farmingObj: any = baseObjFarming

    console.log(farmingObj)

    //Load player data if it exists, and assign existing farming state to farming obj
    let lsSaveData: any = null
    lsSaveData = loadPlayerData()
    if (lsSaveData !== null) {

        //console.log(lsSaveData.farming)
        if (!lsSaveData.farming || lsSaveData.farming === undefined || lsSaveData.farming === null) return farmingObj

        let farmingData = lsSaveData.farming
        for (let farmPatchType in farmingData) {
            //console.log(farmPatchType, farmingData[farmPatchType])

            let farmPatchesOfType = farmingData[farmPatchType]

            for (let farmPatchIndex in farmPatchesOfType) {
                let farmPatch = farmPatchesOfType[farmPatchIndex]
                //console.log(farmPatchIndex, farmPatch)
                farmingObj[farmPatchType][farmPatchIndex] = {...farmingObj[farmPatchType][farmPatchIndex], ...farmPatch}
            }

        }

    }*/

    return baseObjFarming
}

const initialState = initState()

//Reducer definition
export function farmingReducer(state = initialState, action) {

    let farmPatchType
    let farmPatchIndex
    let arrayClone
    let newState

    switch (action.type) {



        case SET_FARM_PATCH_SELECTED_SEED:
            console.log(state, action.payload)
            farmPatchType = action.payload.farmPatchType
            farmPatchIndex = action.payload.farmPatchIndex
            let seedRecipeId = action.payload.seedRecipeId

            arrayClone = [...state[farmPatchType]]
            arrayClone.splice(farmPatchIndex, 1, {
                ...arrayClone[farmPatchIndex],
                selectedSeed: seedRecipeId
            })

            newState = {
                ...state,
                [action.payload.farmPatchType]: arrayClone
            }
            return newState

        case SET_FARM_PATCH_UNLOCKED:
            console.log(action.payload)
            console.log(state)

            farmPatchType = action.payload.farmPatchType
            farmPatchIndex = action.payload.farmPatchIndex

            arrayClone = [...state[farmPatchType]]
            arrayClone.splice(farmPatchIndex, 1, {
                ...arrayClone[farmPatchIndex],
                isUnlocked: action.payload.bool
            })

            newState = {
                ...state,
                [action.payload.farmPatchType]: arrayClone
            }

            return newState

        case ADD_COMPOST:

            farmPatchType = action.payload.farmPatchType
            farmPatchIndex = action.payload.farmPatchIndex

            let currentFertiliserLevel = state[farmPatchType][farmPatchIndex].currentFertiliser
            let currentHarvestModifier = state[farmPatchType][farmPatchIndex].harvestModifier

            let compostId = action.payload.compostId
            let amount = action.payload.amount

            let fertiliserToAdd = 0
            let harvestModifierToAdd = 0

            if (compostId === Items.COMPOST_BUCKET.id) {
                fertiliserToAdd = 10
            }
            if (compostId === Items.SUPERCOMPOST_BUCKET.id) {
                fertiliserToAdd = 100
                harvestModifierToAdd = 20
            }

            fertiliserToAdd *= amount
            fertiliserToAdd = Math.min(fertiliserToAdd, 100 - currentFertiliserLevel)

            newState = editFarmPatchInfo(state, farmPatchType, farmPatchIndex, {
                currentFertiliser: currentFertiliserLevel + fertiliserToAdd,
                harvestModifier: currentHarvestModifier + harvestModifierToAdd
            })

            return newState

            case PLANT_SEED_IN_PATCH:

            farmPatchType = action.payload.farmPatchType
            farmPatchIndex = action.payload.farmPatchIndex
            let farmRecipeId = action.payload.farmRecipeId
            let startTime = action.payload.startTime
            let endTime = action.payload.endTime
            let recipe = FarmingRecipes[farmRecipeId]

            console.log(farmPatchType, farmPatchIndex, recipe)

            newState = editFarmPatchInfo(state, farmPatchType, farmPatchIndex, {
                currentRecipe: farmRecipeId,
                growStartTime: startTime,
                growFinishTime: endTime,
            })
            
            return newState
            case RESET_FARM_PATCH:

            farmPatchType = action.payload.farmPatchType
            farmPatchIndex = action.payload.farmPatchIndex

            newState = editFarmPatchInfo(state, farmPatchType, farmPatchIndex, {
                currentRecipe: null,
                growStartTime: null,
                growFinishTime: null,
                currentFertiliser: 0,
                harvestModifier: 0,
            })

            return newState
        default:
            return state

    }

}

const editFarmPatchInfo = (state, farmPatchType, farmPatchIndex, newFarmPatch) => {


    let arrayClone = [...state[farmPatchType]]
    arrayClone.splice(farmPatchIndex, 1, {
        ...arrayClone[farmPatchIndex],
        ...newFarmPatch
    })

    let newState = {
        ...state,
        [farmPatchType]: arrayClone
    }

    return newState

}

export const getFarmingSlice = (state) => {
    return state.farming
}

export const getSelectedFarmPatchSeed = (state, farmPatchType, farmPatchIndex) => {
    return state.farming[farmPatchType][farmPatchIndex].selectedSeed
}

export const getFarmPatchInfo = (state, farmPatchType, farmPatchIndex) => {
    return state.farming[farmPatchType][farmPatchIndex]
}

export const isFarmPatchInProgress = (state, farmPatchType, farmPatchIndex) => {
    return state.farming[farmPatchType][farmPatchIndex].growFinishTime !== null
}

export const SET_FARM_PATCH_UNLOCKED = "farming/setFarmPatchUnlocked"
export const SET_FARM_PATCH_SELECTED_SEED = "farming/setFarmPatchSelectedSeed"
export const ADD_COMPOST = "farming/addCompost"
export const PLANT_SEED_IN_PATCH = "farming/plantSeedInPatch"
export const RESET_FARM_PATCH = "farming/resetFarmPatch"

export const setFarmPatchUnlocked = (farmPatchType, farmPatchIndex, bool) => ({
    type: SET_FARM_PATCH_UNLOCKED,
    payload: {
        farmPatchType: farmPatchType,
        farmPatchIndex: farmPatchIndex,
        bool: bool,
    }
})

export const setFarmPatchSelectedSeed = (farmPatchType, farmPatchIndex, seedRecipeId) => ({
    type: SET_FARM_PATCH_SELECTED_SEED,
    payload: {
        farmPatchType: farmPatchType,
        farmPatchIndex: farmPatchIndex,
        seedRecipeId: seedRecipeId,
    }
})

export const addCompost = (farmPatchType, farmPatchIndex, compostId, amount) => ({
    type: ADD_COMPOST,
    payload: {
        farmPatchType: farmPatchType,
        farmPatchIndex: farmPatchIndex,
        compostId: compostId,
        amount: amount,
    }
})

export const plantSeedInPatch = (farmPatchType, farmPatchIndex, farmRecipeId, startTime, endTime) => ({
    type: PLANT_SEED_IN_PATCH,
    payload: {
        farmPatchType: farmPatchType,
        farmPatchIndex: farmPatchIndex,
        farmRecipeId: farmRecipeId,
        startTime: startTime,
        endTime: endTime,
    }
})

export const resetFarmPatch = (farmPatchType, farmPatchIndex, farmRecipeId) => ({
    type: RESET_FARM_PATCH,
    payload: {
        farmPatchType: farmPatchType,
        farmPatchIndex: farmPatchIndex,
    }
})
