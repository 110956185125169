import { useTheme } from '@emotion/react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Button, LinearProgress, Popover, SvgIcon } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import Skills from '../game/Skills';
import Screens from '../screens/ScreenData';
import { getCurrentPlayerHitpoints } from '../store/combatReducer';
import { formatMoney, getDiscordInfo } from '../store/helperFuncs';
import { getMoney } from '../store/playerInventory';
import { getMaxHitpoints, getSkillLevel, getSkillLevels } from '../store/playerStats';
import { getAutoEat } from '../store/playerUpgrades';
import CurrentEquipmentCardContent from './CurrentEquipmentCardContent';
import CustomSvgIcon from './CustomSvgIcon';
import EquippedFoodGrid from './EquippedFoodGrid';
import { useDrawerWidth } from './NavDrawerContainer';
import NavDrawerItem from './NavDrawerItem';
import TypographyMemo from './TypographyMemo';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {

        width: `100%`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const EquipmentPopover = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const currentHitpoints = useSelector(getCurrentPlayerHitpoints)
    const maxHitpoints = useSelector(getMaxHitpoints)
    const autoEat = useSelector(getAutoEat)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{
                margin: "0px 8px 0px 0px"
            }}>
                Equipment
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ width: { xs: "350px", md: "400px" }, maxWidth: 1, overflow: "hidden", textAlign: "center", padding: "8px 0px 24px 0px" }}>
                    <Grid container>
                        <Grid xs={12}>
                            <CurrentEquipmentCardContent />
                        </Grid>
                        <Grid xs={12}>
                            <LinearProgress variant='determinate' color='success' value={Math.min((currentHitpoints / maxHitpoints) * 100, 100)} sx={{ width: 0.8, margin: "16px auto 4px auto" }} />
                            <Typography fontSize={14} fontWeight={600}>HP: {currentHitpoints}/{maxHitpoints}</Typography>
                            {(autoEat) ?
                                <Typography fontSize={12} fontWeight={500}>Auto-eat: <SvgIcon component={Skills.HITPOINTS.Icon} sx={{ width: 14, height: 14, marginBottom: "-2px" }} />{Math.floor(maxHitpoints * autoEat.eatThreshold)}</Typography>
                                :
                                <></>
                            }
                        </Grid>
                        <Grid xs={12}>
                            <Box sx={{ margin: "16px 16px 4px 16px" }}>
                                <EquippedFoodGrid size={75} percent />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </div>
    )

}

//Return a list of NavDrawerItems for each screen defined in SkillScreens
const SkillList = React.memo(({ open, closeDrawer }) => {

    let list = []
    let skillLevels = useSelector(getSkillLevels)


    for (let screen in Screens.SkillScreens) {
        let skillLevel = skillLevels[Screens[screen].id]
        let props = {
            key: screen,
            id: Screens[screen].id,
            open: open,
            primaryText: Screens.SkillScreens[screen].name,
            secondaryText: (skillLevel > 0) ? skillLevel + "/99" : null,
            icon: Screens.SkillScreens[screen].icon,
            path: Screens.SkillScreens[screen].path,
            handleClick: closeDrawer
        }

        list.push(
            React.createElement(NavDrawerItem, props)
        )
    }
    return list


}, (a, b) => {
    //console.log(a, b, Object.is(a, b))
    return (a.open === b.open)
})

const CombatNavItems = React.memo(({ open, closeDrawer }) => {

    //let skillLevels = useSelector(getSkillLevels)
    let attackLevel = useSelector(state => getSkillLevel(state, Skills.ATTACK.id))
    let strengthLevel = useSelector(state => getSkillLevel(state, Skills.STRENGTH.id))
    let defenceLevel = useSelector(state => getSkillLevel(state, Skills.DEFENCE.id))
    let rangedLevel = useSelector(state => getSkillLevel(state, Skills.RANGED.id))
    let hitpointsLevel = useSelector(state => getSkillLevel(state, Skills.HITPOINTS.id))

    let currentHitpoints = useSelector(getCurrentPlayerHitpoints)

    return (
        <>
            <NavDrawerItem key={"ATTACK"} id={"ATTACK"} open={open} primaryText={"Attack"} secondaryText={(attackLevel > 0) ? attackLevel + "/99" : null} icon={Skills.ATTACK.Icon} path={"combat"} handleClick={closeDrawer} />
            <NavDrawerItem key={"STRENGTH"} id={"STRENGTH"} open={open} primaryText={"Strength"} secondaryText={(strengthLevel > 0) ? strengthLevel + "/99" : null} icon={Skills.STRENGTH.Icon} path={"combat"} handleClick={closeDrawer} />
            <NavDrawerItem key={"DEFENCE"} id={"DEFENCE"} open={open} primaryText={"Defence"} secondaryText={(defenceLevel > 0) ? defenceLevel + "/99" : null} icon={Skills.DEFENCE.Icon} path={"combat"} handleClick={closeDrawer} />
            <NavDrawerItem key={"RANGED"} id={"RANGED"} open={open} primaryText={"Ranged"} secondaryText={(rangedLevel > 0) ? rangedLevel + "/99" : null} icon={Skills.RANGED.Icon} path={"combat"} handleClick={closeDrawer} />
            <NavDrawerItem key={"HITPOINTS"} id={"HITPOINTS"} open={open} primaryText={"Hitpoints"} secondaryText={((hitpointsLevel > 0) ? hitpointsLevel + "/99" : "")} hpValue={currentHitpoints} icon={Skills.HITPOINTS.Icon} path={"combat"} handleClick={closeDrawer} />
            {/*<NavDrawerItem key={"DEV"} id={"DEV"} open={open} primaryText={"DEV"} icon={Skills.HITPOINTS.Icon} path={""} handleClick={closeDrawer} />*/}
        </>
    )
})

const BankNavItem = ({ open, closeDrawer }) => {

    let money = useSelector(getMoney)

    return <NavDrawerItem key={"BANK"} id={"BANK"} open={open} primaryText={"Bank"} secondaryText={formatMoney(money) + " GC"} secondaryTextColor={(money > 10000000) ? "#0ABB0A" : "#EEDD0A"} icon={AccountBalanceIcon} path={"bank"} handleClick={closeDrawer} />
}

//Content for the drawer on left of the screen
const DrawerContent = React.memo(({ open, closeDrawer }) => {

    let drawerWidth = useDrawerWidth()
    let theme = useTheme()

    return (
        <>
            <DrawerHeader sx={{ maxWidth: drawerWidth }}>
                {/*<IconButton>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
</IconButton>*/}
                <Typography variant='h5' fontWeight={"bold"} zIndex={99999}>GooseScape</Typography>
            </DrawerHeader>
            <Divider />
            <List sx={{ padding: "0px" }}>
                <BankNavItem closeDrawer={closeDrawer} />
                <NavDrawerItem key={"SHOP"} id={"SHOP"} open={open} primaryText={"Shop"} icon={MonetizationOnIcon} path={"shop"} handleClick={closeDrawer} />
                <Divider />
                <Typography fontWeight={600} fontSize={14} sx={{ m: "8px 0px -8px 16px" }} color={theme.palette.text.secondary}>COMBAT</Typography>
                <CombatNavItems open={open} closeDrawer={closeDrawer} />
                <Divider />
                <Typography fontWeight={600} fontSize={14} sx={{ m: "8px 0px -8px 16px" }} color={theme.palette.text.secondary}>NON-COMBAT</Typography>
                <SkillList open={open} closeDrawer={closeDrawer} />
                <Divider sx={{ width: 1 }} />
                <NavDrawerItem key={"STATS"} id={"STATS"} open={open} primaryText={"Stats"} icon={InfoIcon} path={"stats"} handleClick={closeDrawer} />
                <NavDrawerItem key={Screens.ABOUT.id} id={Screens.ABOUT.id} open={open} primaryText={Screens.ABOUT.name} icon={Screens.ABOUT.icon} path={Screens.ABOUT.path} handleClick={closeDrawer} />
                <NavDrawerItem key={Screens.EXTRA.id} id={Screens.EXTRA.id} open={open} primaryText={Screens.EXTRA.name} icon={Screens.EXTRA.icon} path={Screens.EXTRA.path} handleClick={closeDrawer} />
            </List>
        </>
    )
}, (a, b) => {
    //console.log(a, b, Object.is(a, b))
    return (a.open === b.open)
})



const DiscordLogin = React.memo(() => {


    const LOCALHOST_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&scope=identify"
    const DEV_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=https%3A%2F%2Fdev.goosebanana.com%2Flogin&scope=identify"
    const PROD_LOGIN_URI = "https://discord.com/oauth2/authorize?client_id=987766690756583484&response_type=code&redirect_uri=https%3A%2F%2Fidle.goosebanana.com%2Flogin&scope=identify"

    let data = null

    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        console.log(data)
    }, [data])

    

    const getMe = async () => {

        //const data = verifyCode(searchParams.get("code"))
        //console.log(data)

        const discordInfo = await getDiscordInfo(localStorage.getItem("uuid"))
            
        console.log(discordInfo)

        //console.log(data)
        //return data

    }



    return (
        <>
            <Button href={LOCALHOST_LOGIN_URI}>
                Login
            </Button>
            <Button onClick={getMe}>
                Get
            </Button>
        </>
    )

})

function NavBar(props) {

    const { window } = props;

    const container = window !== undefined ? () => window().document.body : undefined;

    const [open, setOpen] = React.useState(false)

    const [currentScreen, setCurrentScreen] = React.useState(Screens.BANK.id)

    const playerMoney = useSelector(getMoney)

    const drawerWidth = useDrawerWidth()

    const toggleDrawerOpen = React.useCallback(() => {
        console.log(open)
        setOpen(!open);
    }, [open])

    const closeDrawer = React.useCallback(() => {
        setOpen(false)
    }, [])

    //Create a hook for current path
    const usePathname = () => {
        let loc = useLocation()
        return loc.pathname
    }
    const path = usePathname()

    //UseEffect listens for path change and sets toolbar's colour and text accordingly
    React.useEffect(() => {

        for (let prop in Screens) {

            if (Screens[prop].path === path) {
                setCurrentScreen(Screens[prop].id)
                break
            }

        }

    }, [path, setCurrentScreen])

    return (
        <>
            <AppBar position="fixed" open={open} sx={{
                '& MuiAppBar-root': { zIndex: 1200 }
            }} >
                <Toolbar sx={{ ml: { xs: 0, md: `${drawerWidth}px` }, bgcolor: Screens[currentScreen].toolbarColour, alignContent: "center", justifyItems: "center" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: "6px", display: { md: 'none' }
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box>
                        <CustomSvgIcon iconToUse={Screens[currentScreen].icon} size={40} bgcolor={"#FAFAFA"} pad={2} margin={"8px 8px 0px 0px"} />
                    </Box>

                    <TypographyMemo variant="h6" noWrap component="div" style={{ flex: 1 }}>
                        {Screens[currentScreen].name}
                    </TypographyMemo>
                    <EquipmentPopover />
                    {/**<DiscordLogin />**/}
                    <TypographyMemo variant="body1" noWrap component="div">
                        {(false) ? `${playerMoney.toLocaleString("en-GB")} GC` : ""}
                    </TypographyMemo>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                variant="temporary"
                open={open}
                onClose={toggleDrawerOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },

                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 1, border: "0px", borderRadius: "0px", zIndex: 1400 },

                }}
            >
                <DrawerContent open={open} closeDrawer={closeDrawer} />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: "0px", borderRadius: "0px", zIndex: 1400 },
                }}
                open
            >
                <DrawerContent open={open} closeDrawer={closeDrawer} />
            </Drawer>
        </>

    )
}

export default NavBar