import { mergeObjects, randIntInterval } from "../store/helperFuncs";

const defaultLootFunction = (state, activity, runCount = 1) => {
    //const activity = getActivity(activityId)
    //console.log(getActivity(activityId))

    let totalLoot = {}
    let totalExp = {}
    for (let k = 0; k < runCount; k++) {
        let loot = {}
        let exp = { [activity.skill]: activity.expGranted }
        //console.log(activity.itemsGiven)
        for (let i in activity.itemsGiven) {

            let lootItem = activity.itemsGiven[i]

            let min = lootItem.minAmount
            let max = lootItem.maxAmount


            let roll = randIntInterval(min, max)

            loot = { ...loot, [lootItem.item]: roll }

        }
        totalExp = mergeObjects(totalExp, exp)
        totalLoot = mergeObjects(totalLoot, loot)
    }

    //console.log(totalLoot, totalExp)
    return [totalLoot, totalExp]

}

//Activity class, all Activity objects within the Activities const should conform to this
export default class BaseActivity {

    id: string = "null"
    name: string = "null"
    skill: string = "null"
    levelRequired: number = 1
    usesItems: boolean = false;
    itemsUsed: any[] = []
    itemsGiven: any[] = []
    baseProgressNeeded: number = 5000
    expGranted: number = 0
    canDoWithFullInventory: boolean = true
    generateLoot: Function = defaultLootFunction
    getUsedItems: Function = (activity, tags) => {
        if (activity.itemsUsed) return activity.itemsUsed
        else return this.itemsUsed ?? null
    }
    getProgressNeeded: Function = (activity, playerUpgrades) => {
        return activity.baseProgressNeeded
    }
}

