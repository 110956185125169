import { SvgIcon, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ItemCount from '../components/ItemCount'
import { damageEnemy, damagePlayer, getCurrentEnemyHp, getCurrentPlayerHitpoints, getEnemy, getPlayer, getPlayerAttackStyle, selectEnemy, setAttackStyle, setCombatState, setEnemyNextAttackTick, setPlayerHitpoints, setPlayerLastHealTick, setPlayerNextAttackTick, startFighting } from '../store/combatReducer'
import { setActivityState, setCurrentActivity } from '../store/currentActivity'
import { getState, mergeObjects } from '../store/helperFuncs'
import { getHasChosenSave } from '../store/miscReducer'
import { addItem, addMoney, consumeAmmo, getEquipmentBonuses, getInventory, getItemInSlot, getPlayerAttackSpeed, setInventoryState } from '../store/playerInventory'
import { addExp, expForLevel, getSkillLevel, getSkillLevels, getStats, setStatsState } from '../store/playerStats'
import { getUpgrades, setUpgradesState } from '../store/playerUpgrades'
import AttackTypes from './AttackTypes'
import { canShootWeapon, CombatMonsters, getAttackRoll, getDefenceRoll, getHitChance, getMaxHit, shouldUseAmmo } from './Combat'
import EquipSlots from './EquipSlots'
import { FarmingRecipes } from './Farming'
import Game from './Game'
import { getItemById, Items, meleeAttackStylesTemplate } from './Items'
import Skills from './Skills'
import StatTypes from './StatTypes'
import ThievingActivities, { getTotalThievingStat } from './Thieving'

export const GameFunctions = React.createContext(null)

const ChildComponent = React.memo((props) => {

    return <>{props.children}</>
}, (a, b) => false)

const GameFunctionsContext = (props) => {

    const dispatch = useDispatch()

    const state = useSelector(getState)

    const playerInventory = useSelector(getInventory)

    const playerHp = useSelector(getCurrentPlayerHitpoints)
    const playerMaxHp = useSelector(state => getSkillLevel(state, Skills.HITPOINTS.id)) * 10
    const enemyHp = useSelector(getCurrentEnemyHp)

    const playerBonuses = useSelector(getEquipmentBonuses)
    const playerUpgrades = useSelector(getUpgrades)
    const playerSkillLevels = useSelector(getSkillLevels)
    const playerStats = useSelector(getStats)

    const player = useSelector(getPlayer)
    let playerAttackSpeed = useSelector(getPlayerAttackSpeed)
    const enemyState = useSelector(getEnemy)
    const enemyObject = CombatMonsters[enemyState.id]

    const playerAttackStyle = useSelector(getPlayerAttackStyle)
    const playerCurrentWeapon = useSelector((state) => getItemInSlot(state, EquipSlots.MAIN_HAND.id))
    const playerCurrentAmmo = useSelector((state) => getItemInSlot(state, EquipSlots.AMMUNITION.id))


    const testFunc1 = React.useCallback((a = "No") => {
        console.log(a)
    }, [])

    const getExpToLevel = React.useCallback((skillId) => {

        let skillLevel = playerSkillLevels[skillId]

        let expForNextLevel = expForLevel(skillLevel + 1)

        let playerExp = playerStats[skillId]
        //TODO MAKE GAME RUN FOR AN AMOUNT OF TIMES BASED ON EXP TO LEVEL
        return expForNextLevel - playerExp
    }, [playerSkillLevels, playerStats])

    const getProgressNeeded = React.useCallback((activity) => {
        return activity.getProgressNeeded(activity, playerUpgrades)
    }, [playerUpgrades])

    const replaceState = React.useCallback((state) => {

        dispatch(setActivityState(state.activity ?? null))
        dispatch(setStatsState(state.stats ?? null))
        dispatch(setInventoryState(state.inventory ?? null))
        dispatch(setUpgradesState(state.upgrades ?? null))
        dispatch(setCombatState(state.combat ?? null))

    }, [dispatch])

    const startCombatFunc = React.useCallback((monsterId) => {
        dispatch(startFighting(true))
        dispatch(setCurrentActivity("COMBAT"))

        let time = Date.now()
        dispatch(setPlayerNextAttackTick(time + playerAttackSpeed))
        dispatch(setEnemyNextAttackTick(time + CombatMonsters[monsterId].attackInterval + 10))
    }, [dispatch, playerAttackSpeed])

    const stopCombatFunc = React.useCallback(() => {
        dispatch(selectEnemy(null))
        dispatch(startFighting(false))
        dispatch(setCurrentActivity(null))
    }, [dispatch])

    const handlePlayerDeath = React.useCallback(() => {
        dispatch(selectEnemy(enemyState.id))
        dispatch(startFighting(false))
        dispatch(setCurrentActivity(null))

        dispatch(setPlayerHitpoints(playerMaxHp))

    }, [dispatch, enemyState.id, playerMaxHp])

    const doQuickEat = React.useCallback(() => {

    }, [])

    const doPlayerHealTick = React.useCallback(() => {
        console.log("HEALING")
        let healableHp = playerMaxHp - playerHp
        dispatch(damagePlayer(-(Math.min(healableHp, 10))))
        dispatch(setPlayerLastHealTick(player.lastHealTick + 60000))
    }, [dispatch, player.lastHealTick, playerHp, playerMaxHp])

    const doPlayerAttack = React.useCallback(() => {

        if (!enemyObject) {
            console.log("No enemy object, stopping combat")
            stopCombatFunc()
            return
        }

        //let playerWeapon = getItemById(playerCurrentWeapon.itemId) ?? null
        //console.log(getItemById(playerCurrentWeapon.itemId), playerWeapon.tags.usesAmmunition, playerWeapon.tags.edible)

        //If weapon uses ammo, check it can be fired then consume ammo accordingly
        if (getItemById(playerCurrentWeapon.itemId)?.tags?.usesAmmunition) {

            if (!canShootWeapon(playerCurrentWeapon.itemId, playerCurrentAmmo.itemId)) {
                console.log("Can't shoot weapon, stopping combat")
                stopCombatFunc()
                return
            }

            if (shouldUseAmmo(state)) {

                dispatch(consumeAmmo(1))

            }

        }

        let playerAttackRoll = getAttackRoll(player.attackStyle, playerSkillLevels, playerBonuses)
        let enemyAttackStyle = enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]].attackType
        let monsterDefenceRoll = getDefenceRoll(enemyAttackStyle, enemyObject.stats.DEFENCE, enemyObject.bonuses)

        let hitChance = getHitChance(playerAttackRoll, monsterDefenceRoll)

        let hitDamage = 0

        let maxHit = getMaxHit(player.attackStyle, playerSkillLevels, playerBonuses, enemyObject?.bonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)

        let hitRoll = Math.random()
        let damageRoll = Math.random()

        //console.log(playerAttackRoll, enemyAttackStyle, monsterDefenceRoll, hitChance, maxHit, hitRoll, damageRoll)

        if (hitRoll < hitChance) {
            hitDamage = Math.floor(damageRoll * maxHit)

            if (hitDamage > enemyHp) {
                hitDamage = enemyHp
            }

            let experienceGained = {
                [Skills.HITPOINTS.id]: hitDamage * ((1 + 1 / 3) / 10)
            }

            for (let skillId in AttackTypes[player.attackStyle].exp) {
                experienceGained = mergeObjects(experienceGained, { [skillId]: AttackTypes[player.attackStyle].exp[skillId] * hitDamage })
            }

            for (let skillId in experienceGained) {
                dispatch(addExp(skillId, experienceGained[skillId]))
            }

        }

        dispatch(damageEnemy(hitDamage))
        dispatch(setPlayerNextAttackTick(player.nextAttackTick + playerAttackSpeed))

    }, [enemyObject, playerCurrentWeapon.itemId, player.attackStyle, player.nextAttackTick, playerSkillLevels, playerBonuses, dispatch, playerAttackSpeed, stopCombatFunc, playerCurrentAmmo, state, enemyHp])

    const doEnemyAttack = React.useCallback(() => {

        if (Object.keys(enemyObject.attackStyles).length === 1) {

            let attackStyle = enemyObject.attackStyles[Object.keys(enemyObject.attackStyles)[0]].attackType
            let monsterAttackRoll = getAttackRoll(attackStyle, enemyObject.stats, enemyObject.bonuses)
            let playerDefenceRoll = getDefenceRoll(player.attackStyle, playerSkillLevels[Skills.DEFENCE.id], playerBonuses, 1)

            let hitChance = getHitChance(monsterAttackRoll, playerDefenceRoll)

            let hitDamage = 0

            let maxHit = getMaxHit(attackStyle, enemyObject.stats, enemyObject.bonuses, playerBonuses[StatTypes.DAMAGE_REDUCTION.id] ?? 0)

            let hitRoll = Math.random()
            let damageRoll = Math.random()

            if (hitRoll < hitChance) {
                hitDamage = Math.floor(damageRoll * maxHit)
                if (hitDamage > playerHp) {
                    hitDamage = playerHp
                }
            }

            dispatch(damagePlayer(hitDamage))
            dispatch(setEnemyNextAttackTick(enemyState.nextAttackTick + CombatMonsters[enemyState.id].attackInterval))

        }

    }, [dispatch, enemyObject, enemyState.id, enemyState.nextAttackTick, player.attackStyle, playerBonuses, playerHp, playerSkillLevels])

    const getItemCount = React.useCallback((itemId) => {

        return playerInventory.inventory[itemId] ?? 0
    }, [playerInventory.inventory])

    const addItemById = React.useCallback((itemId, amount, notify = true) => {
        dispatch(addItem(itemId, amount))
        if (!notify) return
        enqueueSnackbar(
            <><SvgIcon component={Items[itemId].Icon} sx={{ width: 36, height: 36, justifySelf: "center", margin: "-6px 0px", marginRight: "8px", padding: "0px" }} /> {Items[itemId].name} x{amount.toLocaleString("en-GB")} (<ItemCount itemId={itemId} />)</>,
        )
    }, [dispatch])

    //Add exp to a player's given skill, showing a toast with exp quantity
    const addExpToSkill = React.useCallback((skillId, amount) => {
        dispatch(addExp(skillId, amount))
        /*enqueueSnackbar(
        <><SvgIcon component={Skills[skillId].Icon} sx={{width: 32, height: 32, justifySelf: "center",margin: "auto", marginRight: "4px"}}/> +{amount.toLocaleString("en-GB")} {Skills[skillId].name} exp</>
        )*/
    }, [dispatch])

    const addMoneyFunc = React.useCallback((amount, notify = true) => {
        dispatch(addMoney(amount))
        if (!notify) return
        enqueueSnackbar(
            <><Typography>Added {amount.toLocaleString("en-GB")} GooseCoins</Typography></>,
        )
    }, [dispatch])

    const addLootObj = React.useCallback((lootObj, notify = false) => {
        for (let lootItem in lootObj) {
            if (lootItem === Items.GC.id) {
                addMoneyFunc(lootObj[lootItem], notify)
            } else {
                addItemById(lootItem, lootObj[lootItem], notify)
            }
        }
    }, [addItemById, addMoneyFunc])

    const addSkillExpObj = React.useCallback((expObject) => {
        for (let expItem in expObject) {
            addExpToSkill(expItem, expObject[expItem])
        }
    }, [addExpToSkill])

    const addLootExpObjectArr = React.useCallback((lootObj, expObj, notify = true) => {
        if (lootObj) {
            addLootObj(lootObj, notify)
        }
        if (expObj) {
            addSkillExpObj(expObj)
        }
    }, [addLootObj, addSkillExpObj])

    const doFarmingHarvest = React.useCallback((allotmentType, allotmentIndex) => {

        //console.log(allotmentType, allotmentIndex)

        let patchInfo = state.farming[allotmentType][allotmentIndex]

        //console.log(patchInfo)

        if (Date.now() >= patchInfo.growFinishTime) {

            //console.log("harvesting")

            let recipeId = patchInfo.currentRecipe

            let [totalLoot, totalExp] = FarmingRecipes[recipeId].generateLoot(state, allotmentType, allotmentIndex, FarmingRecipes[recipeId])

            return [totalLoot, totalExp]

        }

        return [null, null]

    }, [state])

    const getThievingSuccessChance = React.useCallback((thievingActivityId) => {

        let playerSkillTotal = getTotalThievingStat(state)
        let npcDifficulty = ThievingActivities[thievingActivityId].difficulty

        //console.log(playerSkillTotal, npcDifficulty, (100 + playerSkillTotal) / (100 + npcDifficulty))

        return Math.min(1, (100 + playerSkillTotal) / (100 + npcDifficulty))

    }, [state])

    //When player current weapon or style changes, update current attack style to new one if new weapon has different styles
    React.useEffect(() => {

        let playerAvailableStyles = getItemById(playerCurrentWeapon.itemId)?.attackStyles ?? meleeAttackStylesTemplate
        let isValidStyle = false
        for (let styleId in playerAvailableStyles) {
            if (styleId === playerAttackStyle) {
                isValidStyle = true
                break
            }
        }

        if (!isValidStyle) {
            dispatch(setAttackStyle(playerAvailableStyles[Object.keys(playerAvailableStyles)[0]].id))
        }

    }, [dispatch, playerAttackStyle, playerCurrentWeapon])



    const funcs = React.useMemo(() => {
        return {
            testFunc1: testFunc1,
            doPlayerHealTick: doPlayerHealTick,
            doPlayerAttack: doPlayerAttack,
            doEnemyAttack: doEnemyAttack,
            getItemCount: getItemCount,
            startCombat: startCombatFunc,
            stopCombat: stopCombatFunc,
            handlePlayerDeath: handlePlayerDeath,
            replaceState: replaceState,
            getProgressNeeded: getProgressNeeded,
            doQuickEat: doQuickEat,
            doFarmingHarvest: doFarmingHarvest,
            addLootExpObjectArr: addLootExpObjectArr,
            getExpToLevel: getExpToLevel,
            getThievingSuccessChance: getThievingSuccessChance,
        }
    }, [testFunc1, doPlayerHealTick, doPlayerAttack, doEnemyAttack, getItemCount, startCombatFunc, stopCombatFunc, handlePlayerDeath, replaceState, getProgressNeeded, doQuickEat, doFarmingHarvest, addLootExpObjectArr, getExpToLevel, getThievingSuccessChance])

    const isSaveSelected = useSelector(getHasChosenSave)

    console.log(isSaveSelected)

    return (

        <GameFunctions.Provider value={funcs}>
            {(isSaveSelected) ? <Game /> : <></>}
            <ChildComponent>
                {props.children}
            </ChildComponent>
        </GameFunctions.Provider>

    )
}

export default GameFunctionsContext