import { isStringObject } from 'util/types';

import EquipSlots from '../game/EquipSlots';
import { getItemById, Items } from '../game/Items';
import { baseObjInventory } from './baseObjects';
import { loadPlayerData, mergeObjects } from './helperFuncs';



const initState = () => {

    /*//Generate key value pairs for each skills and its corresponding exp, defaulting to 0
    var inventoryMap: any = {}
    var money = 0
    var equipment = {}

    //Load player data if it exists, and assign existing skill exps to new skill obj
    var lsSaveData: any
    lsSaveData = loadPlayerData()
    if (lsSaveData !== null) {

        if (lsSaveData.inventory !== undefined) {

            if (lsSaveData.inventory.money !== undefined) {
                inventoryMap = lsSaveData.inventory.inventory
                let objStr = "[object Object]"
                let { [objStr]: _, ...rest } = inventoryMap
                inventoryMap = rest

                let undefStr = "undefined"
                let { [undefStr]: __, ...rest2 } = inventoryMap
                inventoryMap = rest2

                for (let prop in inventoryMap) {
                    if (!Items[prop] || Items[prop] === undefined || isNaN(inventoryMap[prop])) {
                        let { [prop]: _, ...newMap } = inventoryMap
                        inventoryMap = newMap
                    }
                }

                money = lsSaveData.inventory.money
            } else {
                inventoryMap = lsSaveData.inventory
            }

            if (lsSaveData.inventory.equipment) {
                for (let slot in EquipSlots) {
                    console.log(slot, lsSaveData.inventory.equipment[slot], typeof lsSaveData.inventory.equipment[slot] === "string")
                    if (typeof lsSaveData.inventory.equipment[slot] === "string") {
                        equipment = {
                            ...equipment,
                            [slot]: {
                                itemId: lsSaveData.inventory.equipment[slot] ?? null,
                                amount: 1,
                            }
                        }
                    } else {

                        if (!lsSaveData.inventory.equipment[slot]) continue

                        equipment = {
                            ...equipment,
                            [slot]: {
                                itemId: lsSaveData.inventory.equipment[slot].itemId ?? null,
                                amount: lsSaveData.inventory.equipment[slot].amount ?? null,
                            }
                        }
                    }

                }
                //equipment = lsSaveData.inventory.equipment
            } else {
                for (let slot in EquipSlots) {
                    console.log(slot)
                    equipment = {
                        ...equipment,
                        [slot]: null
                    }
                }
            }

        }

    }

    return { inventory: inventoryMap, money: money, equipment: equipment }*/
    return baseObjInventory
}

const initialState = initState()

//Reducer definition
export function playerInventoryReducer(state = initialState, action) {

    let newState = { ...state }
    let equippedItemId

    switch (action.type) {


        case SET_WHOLE_STATE:

            if (action.payload === null) return

            return { ...(action.payload) }

        case ADD_ITEM:
            if (action.payload.itemId === Items.GC.id) {
                return playerInventoryReducer(state, addMoney(action.payload.amount))
            }
            const currItemCount = getItemCount(state, action.payload.itemId)
            return { ...state, inventory: { ...state.inventory, [action.payload.itemId]: action.payload.amount + currItemCount } }
        case REMOVE_ITEM:
            if (getItemCount(state, action.payload.itemId) <= action.payload.amount) {
                newState = { ...state, inventory: { ...state.inventory }, money: state.money }
                delete newState.inventory[action.payload.itemId]
                return newState
            } else {
                return { ...state, inventory: { ...state.inventory, [action.payload.itemId]: getItemCount(state, action.payload.itemId) - action.payload.amount } }
            }
        case ADD_MONEY:
            return { ...state, money: state.money + action.payload.amount }
        case EQUIP_ITEM:
            console.log(action)
            let itemToEquip = getItemById(action.payload.itemId)
            equippedItemId = state.equipment[itemToEquip.tags.equipSlot]?.itemId
            console.log(itemToEquip, equippedItemId)

            if (state.inventory[itemToEquip.id] === undefined) return { ...state }

            if (itemToEquip.id === equippedItemId) {
                let itemCount = state.inventory[equippedItemId] ?? 0
                return { ...newState, inventory: { ...newState.inventory, [equippedItemId]: itemCount + state.equipment[itemToEquip.tags.equipSlot].amount }, equipment: { ...newState.equipment, [itemToEquip.tags.equipSlot]: null } }
            }


            let equipAmount = 1

            if (itemToEquip.tags?.stackable) {
                equipAmount = state.inventory[itemToEquip.id]
            }

            for (let slotId in EquipSlots) {

                if (!state.equipment[slotId]) continue

                console.log(slotId, state.equipment[slotId])

                let slotState = state.equipment[slotId]
                let oldItem = getItemById(slotState.itemId)

                //console.log(oldItem)

                if (oldItem.tags?.extraSlots) {
                    console.log(state.equipment[slotId])

                    let itemSlots = oldItem.tags.extraSlots

                    for (let index in itemSlots) {
                        console.log(itemSlots[index], itemToEquip)
                        if (itemSlots[index] === itemToEquip.tags.equipSlot) {
                            console.log(slotState.itemId, newState.inventory[slotState.itemId] ?? 0, slotState.amount)
                            console.log(oldItem.equipSlot)
                            newState = {
                                ...newState,
                                inventory: {
                                    ...newState.inventory,
                                    [slotState.itemId]: (newState.inventory[slotState.itemId] ?? 0) + slotState.amount
                                },
                                equipment: {
                                    ...newState.equipment,
                                    [oldItem.tags.equipSlot]: null
                                }
                            }

                        }
                    }
                }
            }

            if (itemToEquip.tags?.extraSlots) {
                let extraSlots = itemToEquip.tags?.extraSlots
                for (let index in itemToEquip.tags.extraSlots) {
                    console.log(extraSlots[index])

                    let slotState = state.equipment[extraSlots[index]]

                    if (!slotState) continue

                    newState = {
                        ...newState,
                        inventory: {
                            ...newState.inventory,
                            [slotState.itemId]: newState.inventory[slotState.itemId] + slotState.amount
                        },
                        equipment: {
                            ...newState.equipment,
                            [extraSlots[index]]: null
                        }
                    }
                }
            }

            if (equippedItemId === null) {

                newState = {
                    ...newState,
                    inventory: {
                        ...newState.inventory,
                        [itemToEquip.id]: newState.inventory[itemToEquip.id] - equipAmount
                    },
                    equipment: {
                        ...newState.equipment,
                        [itemToEquip.tags.equipSlot]: {
                            itemId: itemToEquip.id,
                            amount: equipAmount
                        }
                    }
                }

                return { ...newState }

            } else {

                newState = {
                    ...newState,
                    inventory: {
                        ...newState.inventory,
                        [itemToEquip.id]: newState.inventory[itemToEquip.id] - equipAmount,
                        [equippedItemId]: (newState.inventory[equippedItemId] ?? 0) + newState.equipment[itemToEquip.tags.equipSlot]?.amount
                    },
                    equipment: {
                        ...newState.equipment,
                        [itemToEquip.tags.equipSlot]: {
                            itemId: itemToEquip.id,
                            amount: equipAmount
                        }
                    }
                }



                return { ...newState }

            }
        case UNEQUIP_SLOT:
            console.log(state, action)
            equippedItemId = state.equipment[action.payload.slotId]
            return { ...newState }
        case USE_AMMO:

            let ammoAmountToUse = action.payload.amount
            let currentAmmoCount = newState.equipment[EquipSlots.AMMUNITION.id]?.amount ?? 0

            newState = {
                ...newState,
                equipment: {
                    ...newState.equipment,
                    [EquipSlots.AMMUNITION.id]: ((currentAmmoCount - ammoAmountToUse) > 0) ?
                        {
                            itemId: newState.equipment[EquipSlots.AMMUNITION.id]?.itemId ?? null,
                            amount: currentAmmoCount - ammoAmountToUse
                        }
                        :
                        null

                }
            }

            return { ...newState }
        default:
            return state

    }

}

//Selectors

//Return count of given item (ID)
export const getItemCount = (state, itemId) => {
    if (state.inventory.inventory !== undefined) state = state.inventory
    //else state
    let itemCount = state.inventory[itemId]
    return (itemCount === undefined) ? 0 : itemCount
}

export const getInventory = (state) => {
    return state.inventory
}

export const getMoney = (state) => {
    return state.inventory.money
}

export const getItemInSlot = (state, slotId) => {
    //console.log(state, slotId)
    return {
        itemId: state.inventory.equipment[slotId]?.itemId,
        amount: state.inventory.equipment[slotId]?.amount
    }
}

export const getEquipment = (state) => {
    let obj = {}
    for (let slotId in EquipSlots) {
        obj = mergeObjects(obj, { [slotId]: state.inventory.equipment[slotId] })
    }
    return obj
}

export const getPlayerAttackSpeed = (state) => {
    let equipItem = getItemById(state.inventory.equipment[EquipSlots.MAIN_HAND.id]) ?? undefined

    if (equipItem === undefined || equipItem.tags === undefined || equipItem.tags.attackSpeed === undefined) return 2400

    let itemAttackSpeed = equipItem.tags.attackSpeed ?? undefined
    if (itemAttackSpeed === undefined) itemAttackSpeed = 2400
    return itemAttackSpeed
}

export const getEquipmentBonuses = (state) => {
    let equipmentSet = state.inventory.equipment
    return getEquipmentBonusesFromSet(equipmentSet)
}

export const getMainHand = (state) => {
    return getItemInSlot(state, EquipSlots.MAIN_HAND.id)
}

//not selector just useful
export const getEquipmentBonusesFromSet = (equipmentSet) => {

    let obj = {}

    //console.log(equipmentSet)

    for (let slot in equipmentSet) {
        if (!equipmentSet[slot]) continue
        let itemId = equipmentSet[slot].itemId ?? undefined
        //console.log(itemId)
        if (itemId === undefined) continue

        let item = getItemById(itemId)

        //console.log(item.tags.stats)

        if (!item.tags || !item.tags.stats) continue

        for (let statId in item.tags.stats) {
            //console.log(statId, item.tags.stats[statId])
            obj = mergeObjects(obj, { [statId]: item.tags.stats[statId] })
        }
    }

    return obj
}

//Action types

const SET_WHOLE_STATE = "inventory/setInventoryState"
export const ADD_ITEM = "inventory/addItem"
export const REMOVE_ITEM = "inventory/removeItem"
export const ADD_MONEY = "money/addMoney"
export const REMOVE_MONEY = "money/removeMoney"
export const EQUIP_ITEM = "equipment/equipItem"
export const UNEQUIP_SLOT = "equipment/unequipSlot"
export const USE_AMMO = "equipment/useAmmo"

//Action creators

export const setInventoryState = (state) => ({
    type: SET_WHOLE_STATE,
    payload: state
})

export const addItem = (itemId, amount) => ({
    type: ADD_ITEM,
    payload: { itemId: itemId, amount: amount }
})

export const removeItem = (itemId, amount) => ({
    type: REMOVE_ITEM,
    payload: { itemId: itemId, amount: amount }
})

export const addMoney = (amount) => ({
    type: ADD_MONEY,
    payload: { amount: amount }
})

export const equipItem = (itemId) => ({
    type: EQUIP_ITEM,
    payload: {
        itemId: itemId
    }
})

export const unequipSlot = (slotId) => ({
    type: UNEQUIP_SLOT,
    payload: {
        slotId: slotId
    }
})

export const consumeAmmo = (amount) => ({
    type: USE_AMMO,
    payload: {
        amount: amount
    }
})