import { useTheme } from '@emotion/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { Items } from '../game/Items'
import { formatMoney } from '../store/helperFuncs'
import { getItemCount, getMoney } from '../store/playerInventory'

const ItemCount = React.memo(({ itemId }) => {
    const theme = useTheme()

    let itemCount = useSelector((state) => getItemCount(state, itemId))

    let textColor = theme.palette.text.primary

    let playerMoney = useSelector(getMoney)
    return (itemId === Items.GC.id) ?
        <span style={{ color: textColor }}>{formatMoney(playerMoney)}</span>
        :
        <span style={{ color: textColor }}>{itemCount.toLocaleString("en-GB")}</span>


}, (a, b) => false)

export default ItemCount

export const ItemCost = React.memo(({ itemId, moreThan = 100, moreColor = "green", lessColor = "red" }) => {
    const theme = useTheme()

    let itemCount = useSelector((state) => getItemCount(state, itemId))
    let playerMoney = useSelector(getMoney)

    let textColor = theme.palette.text.primary

    if (moreThan && itemId !== Items.GC.id) {
        if (itemCount >= moreThan) {
            textColor = moreColor
        } else {
            textColor = lessColor
        }
    }
    if (moreThan && itemId === Items.GC.id) {
        if (playerMoney >= moreThan) {
            textColor = moreColor
        } else {
            textColor = lessColor
        }
    }


    return (itemId === Items.GC.id) ?
        <span style={{ color: textColor }}>{formatMoney(moreThan)}</span>
        :
        <span style={{ color: textColor }}>{moreThan.toLocaleString("en-GB")}</span>


}, (a, b) => false)