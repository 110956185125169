import { useTheme } from '@emotion/react'
import { Box, Button, ButtonBase, SvgIcon, Tooltip, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ScreenGridContainer from '../components/ScreenGridContainer'
import SnackbarItemContent from '../components/SnackbarItemContent'
import { StyledTooltip } from '../components/StyledTooltip'
import { getItemById, Items } from '../game/Items'
import { GeneralShopItemList } from '../game/ShopItems'
import Skills from '../game/Skills'
import Upgrades, { UpgradeIds } from '../game/Upgrades'
import { formatMoney } from '../store/helperFuncs'
import { addItem, addMoney, getInventory, getMoney, removeItem } from '../store/playerInventory'
import { getSkillLevels } from '../store/playerStats'
import { buyUpgradeId, getUpgradeLevel } from '../store/playerUpgrades'

const UpgradeCostArea = ({ upgradeId, upgradeHasRequirements }) => {

    const theme = useTheme()

    let currentLevel = useSelector(state => getUpgradeLevel(state, upgradeId))
    let playerBalance = useSelector(getMoney)
    let playerInventory = useSelector(getInventory)
    let playerSkillLevels = useSelector(getSkillLevels)

    let upgrade = Upgrades[upgradeId]
    let isMaxLevel = (currentLevel === upgrade.levels.length)

    let upgradeCost = 0
    let ingredientCount = 0

    if (!isMaxLevel) {

        upgradeCost = upgrade.levels[currentLevel].cost
        ingredientCount = Object.keys(upgradeCost).length

    }

    let upgradeRequirements = null
    let requirementsJsx = []
    if (upgradeHasRequirements) {
        upgradeRequirements = upgrade.levels[currentLevel].requirements
        //console.log(upgradeRequirements)
        for (let requirementId in upgradeRequirements) {
            requirementsJsx.push(
                <Typography key={requirementId} display={"inline"} fontWeight={500} fontSize={14} color={(playerSkillLevels[requirementId] >= upgradeRequirements[requirementId]) ? theme.palette.success.light : theme.palette.error.light}>{upgradeRequirements[requirementId]} {Skills[requirementId].name}</Typography>
            )
        }
    }

    return (
        <>
            {
                Object.keys(upgradeCost).map((entry, index) => {

                    let isMoney = (entry === Items.GC.id)

                    if (isMoney) {
                        let costColor = (playerBalance >= upgradeCost[entry]) ? theme.palette.success.light : theme.palette.error.light
                        return <Typography display={"inline"} key={entry} fontWeight={500} fontSize={14}>
                            <span style={{ color: costColor }}>{formatMoney(upgradeCost[entry])}
                                <Tooltip arrow placement='top' title={
                                    <Typography fontWeight={600} fontSize={14}>{Items.GC.name}</Typography>
                                }>
                                    <SvgIcon component={Items[entry].Icon} sx={{ width: "16px", height: "16px", margin: "2px 0px -4px 2px" }} />
                                </Tooltip>
                            </span>
                            <span style={{ color: theme.palette.text.primary }}>{(index < ingredientCount - 1) ? ", " : ""}
                            </span>
                        </Typography>
                    }
                    else {
                        let costColor = (playerInventory.inventory[entry] >= upgradeCost[entry]) ? theme.palette.success.light : theme.palette.error.light
                        return <Typography display={"inline"} key={entry} fontWeight={500} fontSize={14}><span style={{ color: costColor }}>{`${upgradeCost[entry].toLocaleString("en-GB")} ${Items[entry].name}`}</span><span style={{ color: theme.palette.text.primary }}>{(index < ingredientCount - 1) ? "," : ""}</span></Typography>
                    }


                })
            }
            {
                (upgradeHasRequirements) ?
                    <>
                        <br />
                        <Typography display={"inline"} fontWeight={500} fontSize={14}>Requires </Typography>{requirementsJsx}
                    </>
                    :
                    <>
                    </>
            }
        </>
    )
}

const UpgradeCard = React.memo(({ upgradeId, upgradeHasRequirements }) => {
    let theme = useTheme()

    let currentLevel = useSelector(state => getUpgradeLevel(state, upgradeId))

    let upgrade = Upgrades[upgradeId]
    let isMaxLevel = (currentLevel === upgrade.levels.length)

    return (

        <Card raised sx={{ padding: "12px", textAlign: "left", bgcolor: theme.palette.primary.light, height: 1, width: 1 }}>
            {(!isMaxLevel) ? <><Typography fontWeight={600} fontSize={18}>{upgrade.levels[currentLevel].name}</Typography>
                <Typography fontWeight={400} fontSize={12}>{upgrade.levels[currentLevel].description}</Typography>
                <Typography fontWeight={600} fontSize={14} sx={{ m: "4px 0px -4px 0px" }}><span style={{ color: "yellow" }}>Cost: </span></Typography>
                <UpgradeCostArea upgradeId={upgradeId} upgradeHasRequirements={upgradeHasRequirements} /></> : <></>}
        </Card>


    )
})

const UpgradeCardButtonBase = ({ upgradeId }) => {

    const dispatch = useDispatch()

    let currentLevel = useSelector(state => getUpgradeLevel(state, upgradeId))
    let playerBalance = useSelector(getMoney)
    let playerInventory = useSelector(getInventory)
    let playerSkillLevels = useSelector(getSkillLevels)

    let upgrade = Upgrades[upgradeId]
    let isMaxLevel = (currentLevel === upgrade.levels.length)

    let upgradeHasRequirements = (upgrade.levels[currentLevel] && upgrade.levels[currentLevel].requirements !== null)


    const canBuyUpgrade = () => {
        let canBuy = true

        for (let itemId in upgrade.levels[currentLevel].cost) {

            let costAmount = upgrade.levels[currentLevel].cost[itemId]

            if (itemId === Items.GC.id) {
                if (playerBalance < costAmount) {
                    return false
                }
                continue
            }
            if (playerInventory.inventory[itemId] === undefined || playerInventory.inventory[itemId] < costAmount) {
                return false
            }

        }

        if (upgradeHasRequirements) {
            for (let requirementId in upgrade.levels[currentLevel].requirements) {
                if (playerSkillLevels[requirementId] < upgrade.levels[currentLevel].requirements[requirementId]) {
                    return false
                }
            }
        }

        return canBuy
    }

    const buyUpgrade = () => {
        if (canBuyUpgrade()) {
            dispatch(buyUpgradeId(upgradeId))
            for (let itemId in upgrade.levels[currentLevel].cost) {
                let costAmount = upgrade.levels[currentLevel].cost[itemId]
                if (itemId === Items.GC.id) {
                    dispatch(addMoney(0 - costAmount))
                    continue
                }
                dispatch(removeItem(itemId, costAmount))
            }
        } else {
            console.log(`Can't afford ${upgrade.levels[currentLevel].name}`)
        }
    }

    return ((!isMaxLevel) ?
        <Grid xs={12} md={6} lg={4}>
            <ButtonBase onClick={buyUpgrade} sx={{ height: 1, width: 1 }}>
                <UpgradeCard upgradeId={upgradeId} upgradeHasRequirements={upgradeHasRequirements} />
            </ButtonBase>
        </Grid>
        :
        <></>
    )
}

const GeneralUpgradesCard = () => {

    return (
        <Card raised sx={{ padding: "12px", textAlign: "center" }}>
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Typography fontWeight={700} fontSize={20}>Skilling Upgrades</Typography>
                </Grid>
                <UpgradeCardButtonBase upgradeId={UpgradeIds.AUTO_EAT} />
                <UpgradeCardButtonBase upgradeId={UpgradeIds.WOODCUTTING_AXE} />
                <UpgradeCardButtonBase upgradeId={UpgradeIds.PICKAXE} />
                <UpgradeCardButtonBase upgradeId={UpgradeIds.FISHING_ROD} />
            </Grid>
        </Card>
    )
}

const BuyableItemCostArea = ({ shopItem, buyAmount }) => {

    const theme = useTheme()
    const playerInventory = useSelector(getInventory)
    //console.log(playerInventory)

    return (
        shopItem.itemCost.map((entry, index) => {
            //console.log(entry)
            let totalCost = entry.amount * (buyAmount ?? 1)
            let hasItem = false
            let item = getItemById(entry.id)
            if (entry.id === Items.GC.id) {
                if (playerInventory.money >= totalCost) {
                    hasItem = true
                }
            } else {
                if (playerInventory.inventory[entry.id] >= totalCost) {
                    hasItem = true
                }
            }
            return (

                <Typography key={entry.id} fontWeight={500} fontSize={14} sx={{ margin: "4px 0px 0px 0px" }}>
                    <StyledTooltip arrow placement='top' title={
                        <Typography fontWeight={500} fontSize={16}>{item.name}</Typography>
                    } sx={{
                        "&.MuiTooltip-popperArrow": {
                            backgroundColor: "black"
                        }
                    }}>
                        <SvgIcon component={getItemById(entry.id).Icon} sx={{ width: 16, height: 16, mb: "-3px", mr: "3px" }} />
                    </StyledTooltip>
                    <span style={{ color: (hasItem ? theme.palette.success.light : theme.palette.error.light) }}>{(entry.amount * buyAmount).toLocaleString("en-gb")}</span>
                </Typography>
            )
        })
    )

}

const BuyableItemCard = ({ shopItem, buyAmount }) => {

    const theme = useTheme()
    const dispatch = useDispatch()

    const playerInventory = useSelector(getInventory)
    const playerSkillLevels = useSelector(getSkillLevels)

    const buyUpgrade = () => {

        console.log(`Trying to buy ${buyAmount}x ${shopItem.name}`)

        let hasItems = true
        let hasRequirements = true

        for (let itemIndex in shopItem.itemCost) {

            let itemId = shopItem.itemCost[itemIndex].id

            let totalCost = shopItem.itemCost[itemIndex].amount * buyAmount

            if (itemId === Items.GC.id) {
                if (playerInventory.money < totalCost) {
                    return
                }
            } else {
                if (playerInventory.inventory[itemId] < totalCost) {
                    return
                }
            }
        }

        for (let skillId in shopItem.itemRequirements) {
            if (playerSkillLevels[skillId] < shopItem.itemRequirements[skillId]) {
                return
            }
        }

        console.log("Can buy")

        for (let itemIndex in shopItem.itemCost) {

            let itemId = shopItem.itemCost[itemIndex].id

            let totalCost = shopItem.itemCost[itemIndex].amount * buyAmount

            if (itemId === Items.GC.id) {
                dispatch(addMoney(0 - totalCost))
                continue
            }
            dispatch(removeItem(itemId, totalCost))
        }

        for (let itemIndex in shopItem.itemToBuy) {

            let itemId = shopItem.itemToBuy[itemIndex].id

            let totalAmount = shopItem.itemToBuy[itemIndex].amount * buyAmount

            if (itemId === Items.GC.id) {
                dispatch(addMoney(totalAmount))
                continue
            }
            dispatch(addItem(itemId, totalAmount))
            enqueueSnackbar(<SnackbarItemContent itemId={itemId} amount={totalAmount} />)
        }

    }

    return (
        <Grid xs={12} md={6} lg={4}>
            <ButtonBase onClick={buyUpgrade} sx={{ height: 1, width: 1 }}>
                <Card raised sx={{ padding: "12px", textAlign: "left", bgcolor: theme.palette.primary.light, height: 1, width: 1 }}>
                    <Grid container>
                        <Grid>
                            <SvgIcon component={getItemById(shopItem.itemToBuy[0].id).Icon} sx={{ width: 90, height: 90, mr: "8px" }} />
                        </Grid>
                        <Grid>
                            <Typography fontWeight={600} fontSize={18}>{shopItem.name} x{(buyAmount).toLocaleString("en-gb")}</Typography>
                            {/*<Typography fontWeight={400} fontSize={12}>{shopItem?.description ?? "No desc"}</Typography>*/}
                            <Typography fontWeight={600} fontSize={14} sx={{ m: "4px 0px -4px 0px" }}><span style={{ color: "yellow" }}>Cost: </span></Typography>
                            <BuyableItemCostArea shopItem={shopItem} buyAmount={buyAmount} />
                        </Grid>
                    </Grid>

                </Card>
            </ButtonBase>
        </Grid>
    )

}

const ItemShopCard = ({ shopItemList }) => {

    const [buyAmount, setBuyAmount] = React.useState(1)

    return (
        <Card raised sx={{ padding: "12px", textAlign: "center" }}>
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Typography fontWeight={700} fontSize={20}>General Shop</Typography>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{ textAlign: "left" }}>
                        <Typography fontWeight={600} fontSize={16}>Buy amount: {buyAmount}</Typography>
                        <Button sx={{ margin: "4px" }} onClick={() => setBuyAmount(1)}>1x</Button>
                        <Button sx={{ margin: "4px" }} onClick={() => setBuyAmount(10)}>10x</Button>
                        <Button sx={{ margin: "4px" }} onClick={() => setBuyAmount(50)}>50x</Button>
                        <Button sx={{ margin: "4px" }} onClick={() => setBuyAmount(100)}>100x</Button>
                        <Button sx={{ margin: "4px" }} onClick={() => setBuyAmount(1000)}>1000x</Button>
                    </Box>
                </Grid>
                {GeneralShopItemList.map((entry, index) => {
                    //console.log(entry)
                    return <BuyableItemCard key={entry.id} shopItem={entry} buyAmount={buyAmount} />
                })}
            </Grid>
        </Card>
    )
}


const BalanceInfoCard = () => {

    const playerBalance = useSelector(getMoney)

    return (
        <Card raised sx={{ padding: "12px", textAlign: "center" }}>

            <Grid container>
                <Grid xs={12}>
                    <Typography fontWeight={700} fontSize={20}>Upgrade/Item Shop</Typography>
                    <Typography fontWeight={500} fontSize={16}>You have <span style={{ color: "yellow" }}>{playerBalance.toLocaleString("en-GB")}</span> GooseCoins</Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

function ShopScreen() {
    return (
        <>
            <ScreenGridContainer maxWidth={1200}>
                <Grid xs={12}>
                    <BalanceInfoCard />
                </Grid>
                <Grid xs={12}>
                    <GeneralUpgradesCard />
                </Grid>
                <Grid xs={12}>
                    <ItemShopCard />
                </Grid>
            </ScreenGridContainer>
        </>
    )
}

export default ShopScreen