import { Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from 'react'
import { usePageVisibility } from 'react-page-visibility';
import { useDispatch, useSelector } from 'react-redux';

import ItemCount from '../components/ItemCount';
import { damagePlayer, eatFoodSlot, getEquippedFood, selectEnemy, setEnemyNextAttackTick, setEnemyRespawn, setPlayerNextAttackTick, startFighting } from '../store/combatReducer';
import { getActivityState, setActivityTags, setCurrentActivity, setLastTimeFinished, startCombat } from '../store/currentActivity';
import { getState, mergeObjects, savePlayerData } from '../store/helperFuncs';
import { getHasChosenSave, getHasLoaded, setHasLoaded } from '../store/miscReducer';
import { addItem, addMoney, getPlayerAttackSpeed, removeItem } from '../store/playerInventory';
import { addExp, getMaxHitpoints } from '../store/playerStats';
import { getAutoEat } from '../store/playerUpgrades';
import { getActivity } from './ActivityFuncs';
import { CombatMonsters } from './Combat';
import simulateCombatUntil from './CombatSimulator';
import { GameFunctions } from './GameFunctionsContext';
import { getItemById, Items } from './Items';
import simulateSkillUntil from './SkillSimulator';

const tickMs = 195

const Game = () => {

    const GameFuncs = React.useContext(GameFunctions)

    //Track overall state, saving to localstorage on a state change
    let state = useSelector(getState)
    useEffect(() => {
        let gameState = {
            activity: state.activity,
            combat: state.combat,
            farming: state.farming,
            inventory: state.inventory,
            stats: state.stats,
            upgrades: state.upgrades,
            
        }
        savePlayerData(gameState, state.misc.saveSlotToUse, state.misc.isCloudSave)

    }, [state])

    let hasLoaded = useSelector(getHasLoaded)

    let isScreenVisible = usePageVisibility()

    //Used to dispatch events to the store (holds state info)
    const dispatch = useDispatch()

    //Used to display/close snackbar (toast) notifications i.e. on getting an item
    const { enqueueSnackbar } = useSnackbar()

    //
    const itemNotification = useCallback((itemId, amount, duration = 2000) => {
        enqueueSnackbar(
            <><SvgIcon component={Items[itemId].Icon} sx={{ width: 36, height: 36, justifySelf: "center", margin: "-6px 0px", marginRight: "8px", padding: "0px" }} /> {Items[itemId].name} x{amount.toLocaleString("en-GB")} (<ItemCount itemId={itemId} />)</>,
            {
                autoHideDuration: duration,
            }
        )
    }, [enqueueSnackbar])

    //Add item to player inventory, showing a toast to user with item and quantity
    const addItemById = useCallback((itemId, amount, notify = true) => {
        dispatch(addItem(itemId, amount))
        if (!notify) return
        enqueueSnackbar(
            <><SvgIcon component={Items[itemId].Icon} sx={{ width: 36, height: 36, justifySelf: "center", margin: "-6px 0px", marginRight: "8px", padding: "0px" }} /> {Items[itemId].name} x{amount.toLocaleString("en-GB")} (<ItemCount itemId={itemId} />)</>,
        )
    }, [dispatch, enqueueSnackbar])

    //Add item to player inventory, showing a toast to user with item and quantity
    const removeItemById = useCallback((itemId, amount) => {
        dispatch(removeItem(itemId, amount))
    }, [dispatch])

    //Add exp to a player's given skill, showing a toast with exp quantity
    const addExpToSkill = useCallback((skillId, amount) => {
        dispatch(addExp(skillId, amount))
        /*enqueueSnackbar(
        <><SvgIcon component={Skills[skillId].Icon} sx={{width: 32, height: 32, justifySelf: "center",margin: "auto", marginRight: "4px"}}/> +{amount.toLocaleString("en-GB")} {Skills[skillId].name} exp</>
        )*/
    }, [dispatch])

    const addMoneyFunc = useCallback((amount, notify = true) => {
        dispatch(addMoney(amount))
        if (!notify) return
        enqueueSnackbar(
            <><Typography>Added {amount.toLocaleString("en-GB")} GooseCoins</Typography></>,
        )
    }, [dispatch, enqueueSnackbar])

    //Returns how many times an activity can be completed given the current state
    const canCompleteActivity = useCallback((activityId, activityTags = null) => {
        let activity = getActivity(activityId)
        if (activity.usesItems === false) return 10000000
        let usedItems = activity.getUsedItems(activity, activityTags)


        let completionCount = 1000000

        for (let index in usedItems) {
            let item = usedItems[index]
            if (state.inventory.inventory[item.id] === undefined || state.inventory.inventory[item.id] < item.amount) {
                return 0
            }
            completionCount = Math.min(completionCount, Math.floor((state.inventory.inventory[item.id] / item.amount)))
        }
        return completionCount
    }, [state.inventory.inventory])

    const addLoot = useCallback((lootObj, notify = false) => {
        for (let lootItem in lootObj) {
            if (lootItem === Items.GC.id) {
                addMoneyFunc(lootObj[lootItem], notify)
            } else {
                addItemById(lootItem, lootObj[lootItem], notify)
            }
        }
    }, [addItemById, addMoneyFunc])


    //Combat logic selectors
    let playerMaxHp = useSelector(getMaxHitpoints)
    let playerAttackSpeed = useSelector(getPlayerAttackSpeed)

    let autoEat = useSelector(getAutoEat)
    let foods = useSelector(getEquippedFood)

    const doCombatLogicTick = React.useCallback(() => {

        if (state.combat.enemy.id === null) {
            dispatch(setCurrentActivity(null))
            dispatch(setActivityTags(null))
            dispatch(startFighting(false))
        }

        let playerHp = state.combat.player.hp
        let enemyHp = state.combat.enemy.hp

        if (state.combat.enemy.respawnTime > Date.now()) {
            return
        }

        if (state.combat.enemy.respawnTime >= 0 && Date.now() > state.combat.enemy.respawnTime) {
            dispatch(selectEnemy(state.combat.enemy.id, false))
            dispatch(setPlayerNextAttackTick(state.combat.enemy.respawnTime + playerAttackSpeed))
            dispatch(setEnemyNextAttackTick(state.combat.enemy.respawnTime + CombatMonsters[state.combat.enemy.id].attackInterval + 10))
            dispatch(setLastTimeFinished(state.combat.enemy.respawnTime))
            return
        }

        //console.log(playerHp, enemyHp, playerAttackSpeed)

        if (enemyHp <= 0) {
            dispatch(setEnemyRespawn(Date.now() + Math.floor(Math.random() * 3000) + 3000))
            let monsterObject = CombatMonsters[state.combat.enemy.id]
            let loot = monsterObject.generateLoot(monsterObject)
            for (let itemId in loot) {
                addItemById(itemId, loot[itemId])
            }
            dispatch(setLastTimeFinished(Date.now()))
            return
        }

        if (playerHp <= 0) {
            dispatch(setLastTimeFinished(Date.now()))
            GameFuncs.handlePlayerDeath()
            return
        }

        if (autoEat !== null) {
            if (playerHp <= Math.round(playerMaxHp * autoEat.eatThreshold)) {
                //console.log(foods, Math.round(playerMaxHp ))
                let canQuickEat = false
                let quickEatIndex = 0
                for (let index in foods) {
                    if (foods[index] !== null) {
                        canQuickEat = true
                        quickEatIndex = index
                        break
                    }
                }
                if (canQuickEat) {
                    dispatch(eatFoodSlot(quickEatIndex))
                    let missingHp = playerMaxHp - playerHp
                    //console.log()
                    let foodItem = getItemById(foods[quickEatIndex].itemId)
                    let foodHeal = Math.min(Math.round(foodItem.tags?.hpHealed * autoEat.foodEfficiency), missingHp)
                    //console.log(autoEat)
                    dispatch(damagePlayer(-foodHeal))
                    return
                }

            }
        }

        //let nextPlayerHealTick = state.combat.player.lastHealTick + 60000
        let nextPlayerAttackTick = state.combat.player.nextAttackTick
        //let nexEnemyHealTick = (state.combat.enemy.lastHealTick ?? Date.now()) + 60000
        let nextEnemyAttackTick = (state.combat.enemy.nextAttackTick ?? Date.now())


        let ticks = [
            {
                key: "nextPlayerAttackTick", value: nextPlayerAttackTick, func: GameFuncs.doPlayerAttack
            },
            /*{
                key: "nexEnemyHealTick", value: nexEnemyHealTick
            },*/
            {
                key: "nextEnemyAttackTick", value: nextEnemyAttackTick, func: GameFuncs.doEnemyAttack
            }
        ]

        ticks.sort((obj1, obj2) => { return obj1.value - obj2.value })

        /*ticks.map((entry) => {
            console.log(entry.key, entry.value)
            return null
        })*/

        if (Date.now() > ticks[0].value) {
            ticks[0].func()
            dispatch(setLastTimeFinished(Date.now()))
        }

    }, [GameFuncs, addItemById, autoEat, dispatch, foods, playerAttackSpeed, playerMaxHp, state.combat.enemy.hp, state.combat.enemy.id, state.combat.enemy.nextAttackTick, state.combat.enemy.respawnTime, state.combat.player.hp, state.combat.player.nextAttackTick])

    //Set an interval to run a game tick every {tickMs}ms
    let activity = useSelector(getActivityState)

    let hasChosenSave = useSelector(getHasChosenSave)
    
    const ret = useEffect(() => {

        if (ret) ret()

        const doGameTick = () => {

            //TODO CHECK FOR HEAL TICK OUTSIDE OF COMBAT HERE

            if (!hasChosenSave) return

            if (!isScreenVisible) {
                if (activity.currentActivity === "COMBAT" && hasLoaded === true) {
                    dispatch(setHasLoaded(false))
                }
                return
            }

            if (activity.currentActivity === null) {
                if (!hasLoaded) dispatch(setHasLoaded(true))
                return
            }

            if (activity.currentActivity === "COMBAT") {

                if (hasLoaded === false) {
                    let [newState, expAndLoot] = simulateCombatUntil(state, Date.now())
                    console.log(state, newState, expAndLoot)
                    if (newState) {
                        console.log(newState, expAndLoot)
                        GameFuncs.replaceState(newState)
                        for (let itemId in expAndLoot.totalLoot) {
                            let amount = expAndLoot.totalLoot[itemId]
                            addItemById(itemId, amount)
                        }
                    }

                    dispatch(setHasLoaded(true))
                    return
                }

                if (state.combat.player.nextAttackTick < Date.now() - 10000 || state.combat.enemy.nextAttackTick < Date.now() - 10000) {
                    dispatch(setHasLoaded(false))
                    return
                }

                doCombatLogicTick()

                return
            }

            if (!hasLoaded) {
                let lastActivityFinish = Math.max(state.activity?.lastActivityFinish ?? 0, Date.now() - 24 * 60 * 60)
                dispatch(setLastTimeFinished(lastActivityFinish))
                let [newState, expAndLoot] = simulateSkillUntil(state, Date.now())
                console.log(newState, expAndLoot)
                GameFuncs.replaceState(newState)
                for (let itemId in expAndLoot.totalLoot) {
                    let amount = expAndLoot.totalLoot[itemId]
                    //addItemById(itemId, amount)
                    itemNotification(itemId, amount, 5000)
                }
                dispatch(setHasLoaded(true))
                return
            }

            let currActivity = getActivity(activity.currentActivity)

            let finishTime = activity.lastActivityFinish + GameFuncs.getProgressNeeded(currActivity)

            if (Date.now() - activity.lastActivityFinish > 1000 * 60 * 5 && hasLoaded && activity.currentActivity !== null) {
                dispatch(setHasLoaded(false))
                return
            }

            //Logic for handling activity finish
            if (Date.now() >= finishTime) {

                let loopCount = 0

                let totalLoot = {}
                let totalExp = {}

                let timesCanComplete = canCompleteActivity(currActivity.id, activity.tags)

                while (Date.now() > finishTime) {

                    if (timesCanComplete === 0 || loopCount === timesCanComplete) {
                        enqueueSnackbar(<Typography>Ran out of items!</Typography>)
                        dispatch(setCurrentActivity(currActivity.id))
                        dispatch(setActivityTags(null))
                        break
                    }

                    loopCount++

                    let usedItems = currActivity.getUsedItems(currActivity, activity.tags)

                    let [loot, exp] = currActivity.generateLoot(state, currActivity, 1)

                    addLoot(loot)
                    for (let expDrop in exp) {
                        addExpToSkill(expDrop, exp[expDrop])
                    }

                    totalLoot = mergeObjects(totalLoot, loot)
                    totalExp = mergeObjects(totalExp, exp)

                    for (let index in usedItems) {
                        let item = usedItems[index]
                        removeItemById(item.id, item.amount)

                    }

                    if (timesCanComplete > 1) {
                        finishTime += GameFuncs.getProgressNeeded(currActivity)
                    } else {
                        enqueueSnackbar(<Typography>Ran out of items!</Typography>)
                        dispatch(setCurrentActivity(currActivity.id))
                        dispatch(setActivityTags(null))
                        break
                    }

                }

                dispatch(setLastTimeFinished(activity.lastActivityFinish + (GameFuncs.getProgressNeeded(currActivity) * loopCount)))

                for (let i in totalLoot) {
                    if (i === Items.GC.id) {
                        enqueueSnackbar(
                            <><Typography>Added {totalLoot[i].toLocaleString("en-GB")} GooseCoins</Typography></>
                        )
                    } else {
                        enqueueSnackbar(
                            <><SvgIcon component={Items[i].Icon} sx={{ width: 36, height: 36, justifySelf: "center", margin: "-6px 0px", marginRight: "8px", padding: "0px" }} /> {Items[i].name} x{totalLoot[i].toLocaleString("en-GB")} (<ItemCount itemId={i} />)</>,
                            { style: { padding: "-12px" } }
                        )
                    }
                    console.log(loopCount, `Added ${totalLoot[i]} ${i}`)

                }

            }

            if (!hasLoaded) dispatch(setHasLoaded(true))
        }

        const tickInterval = setInterval(() => { doGameTick() }, tickMs)

        return () => clearInterval(tickInterval)

    }, [activity, dispatch, addExpToSkill, addItemById, state, addMoneyFunc, enqueueSnackbar, removeItemById, hasLoaded, canCompleteActivity, addLoot, isScreenVisible, doCombatLogicTick, GameFuncs, itemNotification])

    return (
        <>
        </>
    )
}

export default Game